import ImageSlider from "./imageslider.js";
const App = () => {
  const slides = [
    {
      url: "https://bumdessidomulyo.com/image-1.png",
      title: "beach",
    },
    {
      url: "https://bumdessidomulyo.com/image-2.png",
      title: "boat",
    },
    {
      url: "https://bumdessidomulyo.com/image-3.png",
      title: "forest",
    },
    {
      url: "https://bumdessidomulyo.com/image-2.png",
      title: "city",
    },
    {
      url: "https://bumdessidomulyo.com/image-1.png",
      title: "italy",
    },
  ];
  const containerStyles = {
    width: "1600px",
    height: "680px",
    margin: "0 auto",
  };
  return (
    <div>
      <div style={containerStyles}>
        <ImageSlider slides={slides} />
      </div>
    </div>
  );
};

export default App;
