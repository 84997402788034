import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Pembayaran.css"; // Import file CSS untuk gaya modal

const Selesai = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchCartItems = async () => {
      try {
        const response = await axios.get(
          `https://bumdessidomulyo.com/api/transaksi/complete`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching completed transactions:", error);
      }
    };
    fetchCartItems();
  }, []);

  return (
    <div className="container mt-3">
      <h2 style={{ textAlign: "center", marginBottom: 10 }}>Riwayat</h2>
      <h3 style={{ textAlign: "center", marginBottom: 10 }}>
        Daftar Riwayat Pesanan
      </h3>
      {data && Array.isArray(data) && data.length > 0 ? (
        data.map((item, index) => (
          <div key={index} className="pesanan-card">
            <h3>Kode TR: {item.id}</h3>
            <p>Metode Pembayaran: {item.midtrans.payment_type}</p>
            <p>Status: {item.midtrans.transaction_status}</p>
            <p>Pembayaran Dilakukan Pada: {item.midtrans.settlement_time}</p>
          </div>
        ))
      ) : (
        <div className="empty-history">
          <i className="fas fa-history fa-5x"></i>
          <p>Anda belum memiliki riwayat pesanan.</p>
          <p>Yuk, mulai berbelanja sekarang!</p>
          <button className="btn-belanja" onClick={() => navigate("/product")}>
            Belanja
          </button>
        </div>
      )}
    </div>
  );
};

export default Selesai;
