import React from "react";
import Heading from "../../common/Heading";
import "./style.css";

const PasarSindon = () => {
  return (
    <>
      <section className='about'>
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading
              title='Pasar Sindon'
              subtitle='CJGC+4PG, Sindon, Sidomulyo, Kec. Wonoasri, Kabupaten Madiun, Jawa Timur 63157'
            />
            <p>
              Pasar legend ini, pasar tradisional tempat bertemunya pembeli dan penjual lintas generasi
              Ramai waktu pagi mencerminkan bahwa pasar ini sangat membantu masyarakat sekitar mulai dari pedagang maupun pembeli, kalau siang tinggal yang di ruko-ruko
              Nuansa tradisional masih terasa banget, penjual lesehan, tawar-menawar, bahkan ada yang barter kalau cocok. Kalau sore banyak sekali UMKM yang berdagang di Pasar Sindon ini. Semoga pasar seperti ini tidak tergusur modernitas. Sehingga Pasar ini mengenalkannya melalui website agar Pasar Sindon lebih dikenal banyak orang tidak hanya masyarakat Kabupaten Madiun namun masyarakat Indonesia.
            </p>
            <div className='btn2-container'>
              <a href="https://pasarsindon.com/" className="btn2" target="_blank" rel="noopener noreferrer">Kunjungi</a>
            </div>
          </div>
          <div className='right row'>
            <img src='./immio1.png' alt='' />
          </div>
        </div>
      </section>
    </>
  );
};

export default PasarSindon;
