import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import "./Detailproduk.css";

const DetailBarang = () => {
  const { id } = useParams();
  const [barang, setBarang] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBarang = async () => {
      try {
        const response = await axios.get(
          `https://bumdessidomulyo.com/api/barang/detail/${id}`
        );
        setBarang(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBarang();
  }, [id]);

  const addToCart = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        alert(
          "Anda harus login terlebih dahulu untuk menambahkan barang ke keranjang."
        );
        navigate("/login");
        return;
      }

      const response = await axios.post(
        "https://bumdessidomulyo.com/api/transaksi/Cart",
        {
          barang_id: id,
          jumlah: quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      navigate("/keranjang");
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    }
  };

  const formatRupiah = (angka) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    });
    return formatter.format(angka);
  };

  const renderHarga = (harga) => {
    return formatRupiah(harga);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container-detail mt-3">
      <div className="row">
        <div className="col-md-6">
          <img
            src={`https://bumdessidomulyo.com/api/public/uploads/${barang.gambar}`}
            alt={barang.nama}
            className="img-fluid"
          />
        </div>
        <div className="col-md-6">
          <div className="detail-barang">
            <h2 className="detail-nama">{barang.nama}</h2>
            <p className="detail-harga">{renderHarga(barang.harga)}</p>
            <hr className="divider" />
            <p className="detail-deskripsi">{barang.deskripsi}</p>
            <p>
              <strong>Stok:</strong> {barang.stok}
            </p>
            <div className="mb-3 d-flex align-items-center justify-content-center">
              <button
                className="btn-whatsapp"
                onClick={() =>
                  window.open(
                    `https://wa.me/089670693200?text=Hi,%20I%20am%20interested%20in%20${barang.nama}`,
                    "_blank"
                  )
                }
              >
                <i className="fab fa-whatsapp"></i> Chat via WhatsApp
              </button>
            </div>
            <div className="mb-3 d-flex align-items-center justify-content-center">
              <div className="quantity-control">
                <button
                  className="quantity-btn"
                  onClick={() =>
                    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1))
                  }
                  disabled={barang.stok === 0}
                >
                  -
                </button>
                <input
                  type="number"
                  id="quantity"
                  className="form-control quantity-input"
                  min="1"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  disabled={barang.stok === 0}
                />
                <button
                  className="quantity-btn"
                  onClick={() =>
                    setQuantity((prevQuantity) => prevQuantity + 1)
                  }
                  disabled={barang.stok === 0}
                >
                  +
                </button>
              </div>
            </div>
            <button
              className={`btn btn-primary ${
                barang.stok === 0 ? "disabled" : ""
              }`}
              onClick={addToCart}
              disabled={barang.stok === 0}
            >
              {barang.stok === 0 ? "Out of Stock" : "Add to Cart"}
            </button>
            {error && <p className="text-danger mt-2">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailBarang;
