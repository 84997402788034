export const nav = [
  {
    text: "beranda",
    path: "/",
  },
  {
    text: "produk",
    path: "/product",
  },
  {
    text: "tentang kami",
    path: "/about",
  },
  {
    text: "hubungi kami",
    path: "/contact",
  },
]


export const navLogined = [
  {
    text: "beranda",
    path: "/",
  },
  {
    text: "produk",
    path: "/product",
  },
  {
    text: "tentang kami",
    path: "/about",
  },
  {
    text: "hubungi kami",
    path: "/contact",
  }
];

export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Lokasi Strategis",
    total: "",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Harga Terjangkau",
    total: "",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Produk Beragam",
    total: "",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Pelayanan Unggul",
    total: "",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Terpercaya",
    total: "",
  },
]
export const list = [
  {
    id: 1,
    cover: "../images/list/p-1.png",
    name: "Red Carpet Real Estate",
    location: "210 Zirak Road, Canada",
    category: "For Rent",
    price: "$3,700",
    type: "Apartment",
  },
  {
    id: 2,
    cover: "../images/list/p-2.png",
    name: "Fairmount Properties",
    location: "5698 Zirak Road, NewYork",
    category: "For Sale",
    price: "$9,750",
    type: "Condos",
  },
  {
    id: 3,
    cover: "../images/list/p-7.png",
    name: "The Real Estate Corner",
    location: "5624 Mooker Market, USA",
    category: "For Rent",
    price: "$5,860",
    type: "Offices",
  },
  {
    id: 4,
    cover: "../images/list/p-4.png",
    name: "Herringbone Realty",
    location: "5621 Liverpool, London",
    category: "For Sale",
    price: "$7,540",
    type: "Homes & Villas",
  },
  {
    id: 5,
    cover: "../images/list/p-5.png",
    name: "Brick Lane Realty",
    location: "210 Montreal Road, Canada",
    category: "For Rent",
    price: "$4,850",
    type: "Commercial",
  },
 
]
export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "",
    name: "UMKM Terbaik",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "",
    name: "Terjangkau",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "",
    name: "Inovatif",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "",
    name: "Terpercaya",
  },
]
export const location = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-2.png",
  },
  {
    id: 3,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-3.png",
  },
  {
    id: 4,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-4.png",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-5.png",
  },
  {
    id: 6,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-6.png",
  },
]
export const price = [
  {
    plan: "Basic",
    price: "29",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "120GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "5GB Cloud Storage",
      },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    best: "Best Value",
    plan: "Standard",
    price: "49",
    ptext: "per user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "150GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "10GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        change: "color",
        icon: <i class='fa-solid fa-x'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "79",
    ptext: "2 user, per month",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "200GB CDN Bandwidth",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "20GB Cloud Storage",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "MENU",
    text: [
      { text: "Beranda", path: "/" }, 
      { text: "Produk", path: "/product" }, 
      { text: "Tentang Kami", path: "/about" }, 
      { text: "Hubungi Kami", path: "/contact" }
    ],
  },
  {
    title: "KONTAK",
    text: [
      { text: "Email: bumdessidomulyo@gmail.com" }, 
      { text: "No telp: 089670693200 (Bumdes)" }, 
      { text: "085736424499" },
      { text: "TikTok: @bumdes.multi.guna" },
      { text: "Instagram: @bumdes_multiguna57" },
      { text: "Alamat: CJGC+4PG, Sindon, Sidomulyo, Kec. Wonoasri, Kabupaten Madiun, Jawa Timur 63157" }
    ],
  }
];
