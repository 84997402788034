import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import img from "../images/services1.png";
import Back from "../common/Back";
import "./Service.css";
import "./Servicecard.css";
import ReactPaginate from "react-paginate";

const Product = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const productsPerPage = 9;

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "https://bumdessidomulyo.com/api/barang"
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://bumdessidomulyo.com/api/kategori"
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const filteredProducts = products.filter((product) => {
    return (
      product.nama.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedCategory === "" || product.kategori_id === selectedCategory)
    );
  });

  const indexOfLastProduct = (pageNumber + 1) * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const formatRupiah = (angka) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    });
    return formatter.format(angka);
  };

  const renderHarga = (harga) => {
    return formatRupiah(harga);
  };

  return (
    <>
      <section className="services mb">
        <Back name="Products" title="Products - All Products" cover={img} />
        <div className="filter-container">
          <div className="search-container">
            <input
              type="text"
              placeholder="Cari produk..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="category-filter">
            <select value={selectedCategory} onChange={handleCategoryChange}>
              <option value="">Semua Kategori</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.nama}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="service container">
          {currentProducts.map((product) => (
            <Link
              key={product.id}
              to={`/barang/detail/${product.id}`}
              className="card-service"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div>
                <img
                  src={
                    "https://bumdessidomulyo.com/api/public/uploads/" +
                    product.gambar
                  }
                  className="card-img-top"
                  alt={product.nama}
                />
                <div className="card-body">
                  <div className="card-title-container">
                    <h5 className="card-title">{product.nama}</h5>
                    <p className="category-name">{product.nama_kategori}</p>
                  </div>
                  <p className="card-text">{renderHarga(product.harga)}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(filteredProducts.length / productsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      </section>
    </>
  );
};

export default Product;
