import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "./Keranjang.css";

const Keranjang = () => {
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchCartItems = async () => {
      try {
        const response = await axios.get(
          `https://bumdessidomulyo.com/api/transaksi/Cart`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCartItems(response.data);
        let calculatedTotal = 0;
        response.data.forEach((item) => {
          calculatedTotal += parseInt(item.harga) * item.jumlah;
        });
        setTotal(calculatedTotal);
      } catch (error) {
        console.error("Error fetching cart items:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    // Set a delay to show the loading spinner
    const delayTimeout = setTimeout(() => {
      if (loading) {
        setShowLoading(true);
      }
    }, 500);

    fetchCartItems();

    return () => clearTimeout(delayTimeout);
  }, []);

  const handleIncrementQuantity = (itemId) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.barang_id === itemId) {
        return { ...item, jumlah: item.jumlah + 1 };
      }
      return item;
    });
    setCartItems(updatedCartItems);
    updateTotal(updatedCartItems);
  };

  const handleDecrementQuantity = (itemId) => {
    const updatedCartItems = cartItems
      .map((item) => {
        if (item.barang_id === itemId) {
          const newQuantity = item.jumlah - 1;
          return newQuantity < 1 ? null : { ...item, jumlah: newQuantity };
        }
        return item;
      })
      .filter((item) => item !== null);
    setCartItems(updatedCartItems);
    updateTotal(updatedCartItems);
  };

  const formatRupiah = (angka) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    });
    return formatter.format(angka);
  };

  const renderHarga = (harga) => {
    return formatRupiah(harga);
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    newQuantity = parseInt(newQuantity);
    if (newQuantity < 1) {
      removeCartItem(itemId);
      return;
    }

    const updatedCartItems = cartItems.map((item) => {
      if (item.barang_id === itemId) {
        return { ...item, jumlah: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCartItems);
    updateTotal(updatedCartItems);
  };

  const updateTotal = (updatedCartItems) => {
    let calculatedTotal = 0;
    updatedCartItems.forEach((item) => {
      calculatedTotal += parseInt(item.harga) * item.jumlah;
    });
    setTotal(calculatedTotal);
  };

  const removeCartItem = async (itemId) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(
        `https://bumdessidomulyo.com/api/transaksi/Cart/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedCartItems = cartItems.filter(
        (item) => item.barang_id !== itemId
      );
      setCartItems(updatedCartItems);
      updateTotal(updatedCartItems);
    } catch (error) {
      console.error("Error removing cart item:", error);
      setError(error.message);
    }
  };

  const handleCheckout = () => {
    navigate("/checkout", { state: { cartItems, total } });
  };

  if (loading && showLoading)
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );

  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mt-5 d-flex flex-column align-items-center">
      <h2 className="mb-4">Keranjang Belanja</h2>
      {cartItems.length === 0 ? (
        <div className="empty-cart">
          <i className="fas fa-shopping-cart"></i>
          <p>Keranjang kosong</p>
          <Link to="/product" className="shop-now-btn">
            Belanja Sekarang
          </Link>
        </div>
      ) : (
        <div className="w-100">
          {cartItems.map((item) => (
            <div key={item.barang_id} className="card border-custom mb-3">
              <div className="row g-0">
                <div className="col-md-4 card-content-center">
                  <img
                    src={`https://bumdessidomulyo.com/api/public/uploads/${item.gambar_barang}`}
                    alt={item.nama_barang}
                    className="img-thumbnail-custom"
                  />
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <div className="card-body flex-grow-1">
                    <h5 className="card-title">{item.nama_barang}</h5>
                    <p className="card-text">
                      Harga : {renderHarga(item.harga)}
                    </p>
                    <p className="card-text">Jumlah: {item.jumlah}</p>
                  </div>
                  <div className="quantity-control">
                    <button
                      className="quantity-btn"
                      onClick={() => handleDecrementQuantity(item.barang_id)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      id="quantity"
                      className="form-control quantity-input"
                      min="1"
                      value={item.jumlah}
                      onChange={(e) =>
                        handleQuantityChange(item.barang_id, e.target.value)
                      }
                    />
                    <button
                      className="quantity-btn"
                      onClick={() => handleIncrementQuantity(item.barang_id)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-end align-items-center">
            <h5 className="me-3">Total: {renderHarga(total)}</h5>
            <button className="checkout-button" onClick={handleCheckout}>
              Checkout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Keranjang;