import React, { useState } from "react";
import emailjs from "emailjs-com";
import img from "../images/pricing1.png";
import Back from "../common/Back";
import "./contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "your_service_id", // Ganti dengan Service ID Anda
        "your_template_id", // Ganti dengan Template ID Anda
        formData,
        "your_user_id" // Ganti dengan User ID Anda
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Pesan berhasil dikirim!");
        },
        (error) => {
          console.log(error.text);
          alert("Terjadi kesalahan, pesan tidak terkirim.");
        }
      );
  };

  return (
    <>
      <section className="contact mb">
        <Back name="Hubungi kami" title="Dapatkan Bantuan & Dukungan" cover={img} />
        <div className="container">
          <form className="shadow" onSubmit={handleSubmit}>
            <h4>Hubungi kami</h4> <br />
            <div className="form-group">
              <input
                type="text"
                placeholder="Nama"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <input
              type="text"
              placeholder="Subjek"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
            <textarea
              cols="30"
              rows="10"
              placeholder="Pesan"
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <button type="submit" className="btn-submit">Kirim</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
