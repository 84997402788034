import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./sukses.css"; // Import file CSS untuk gaya halaman checkout sukses

const CheckoutSuccess = () => {
    const navigate = useNavigate();

    const handlePembayaranClick = () => {
        navigate("/payments"); // Arahkan ke halaman pembayaran
    };

    return (
        <div className="checkout-success-container">
            <i className="fas fa-check-circle fa-5x"></i>
            <h2>Checkout Success</h2>
            <p>Pesanan anda telah berhasil dikonfirmasi. Terima kasih telah berbelanja dengan kami!</p>
            <p>Ayo lakukan pembayaran sekarang</p>
            <Link to="/payments" className="btn-pembayaran">
                Pembayaran
            </Link>
        </div>
    );
};

export default CheckoutSuccess;
