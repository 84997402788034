import React from "react";
import { footer } from "../../data/Data";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="box">
            <div className="logo">
              <img src="../images/logo3.png" alt="" />
              <p>Hubungi kami lewat email</p>
              <div className="input-container">
                <input type="text" placeholder="Email Address" />
                <button className="subscribe-button">Subscribe</button>
              </div>
            </div>
          </div>

          {footer.map((val) => (
            <div className="box" key={val.title}>
              <h3>{val.title}</h3>
              <ul>
                {val.text.map((item, index) => (
                  <li key={index}>
                    {item.path ? (
                      <Link to={item.path} className="footer-link">
                        {item.text}
                      </Link>
                    ) : (
                      item.text
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer>
      <div className="legal">
        <span>©2024 Bumdes Sidomulyo. All Rights Reserved</span>
      </div>
    </>
  );
};

export default Footer;
