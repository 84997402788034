import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "./bestselling.css";
import Heading from "../../common/Heading";

const BestSelling = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBestSellingProducts = async () => {
      try {
        const response = await axios.get(
          "https://bumdessidomulyo.com/api/barang/bestseller"
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching best selling products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBestSellingProducts();
  }, []);

  const formatRupiah = (angka) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    });
    return formatter.format(angka);
  };

  const renderHarga = (harga) => {
    return formatRupiah(harga);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <ClipLoader color={"#36D7B7"} size={150} />
      </div>
    );
  }

  return (
    <section className="mb background">
      <div className="container">
        <div className="heading-container">
          <h1>Produk Unggulan</h1>
          <Link to="/product" className="lihat-semua-btn">
            Lihat Semua <i className="fas fa-arrow-right"></i>
          </Link>
        </div>
        <div className="recomended">
          {products.map((product) => (
            <Link
              key={product.id}
              to={`/barang/detail/${product.id}`}
              className="card-section"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div>
                <img
                  src={`https://bumdessidomulyo.com/api/public/uploads/${product.gambar}`}
                  className="card-img"
                  alt={product.nama}
                />
                <div className="card-body">
                  <div className="card-title-container">
                    <h5 className="card-titles">{product.nama}</h5>
                    <p className="category-name">{product.nama_kategori}</p>
                  </div>
                  <p className="card-texts">{renderHarga(product.harga)}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BestSelling;
