import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Gopay from "./Gopay";
import "./checkout.css"; // Use the same CSS file for consistency

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState(location.state.cartItems);
  const [total, setTotal] = useState(location.state.total);
  const [showModal, setShowModal] = useState(false);
  const [gopay, setGopay] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [alamat, setAlamat] = useState(""); // State for address
  const [error, setError] = useState(""); // State for error message

  const handleBackToCart = () => {
    navigate("/keranjang");
  };

  const handleConfirmOrder = () => {
    if (!alamat) {
      setError("Alamat harus diisi");
      return;
    }
    setShowModal(true);
    setError(""); // Clear error message
  };

  const handleModalClose = () => {
    setShowModal(false);
    setGopay(false);
  };

  const formatRupiah = (angka) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    });
    return formatter.format(angka);
  };

  const renderHarga = (harga) => {
    return formatRupiah(harga);
  };

  const handlePaymentMethodSelect = async (paymentType) => {
    let cartItemsRequest = cartItems.map((item) => ({
      name: item.nama_barang,
      barang_id: item.barang_id,
      quantity: item.jumlah ?? 1,
    }));

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://bumdessidomulyo.com/api/transaksi/checkout",
        {
          order_details: cartItemsRequest,
          payment_methode: paymentType,
          alamat: alamat, // Pastikan alamat dimasukkan ke dalam payload
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Order confirmed:", response.data);
      if (response.data.payments.payment_type === "gopay") {
        console.log(response.data.payments.actions[0].url);
        setImageUrl(response.data.payments.actions[0].url);
        setGopay(true);
        setShowModal(false);
      } else {
        navigate("/sukses");
      }
    } catch (error) {
      console.error("Error confirming order:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Checkout</h2>
      <div className="row">
        <div className="col-md-8">
          {cartItems.length === 0 ? (
            <p>Keranjang kosong</p>
          ) : (
            <div>
              {cartItems.map((item) => (
                <div key={item.barang_id} className="card border-custom mb-3">
                  <div className="row g-0">
                    <div className="col-md-4 card-content-center">
                      <img
                        src={`https://bumdessidomulyo.com/api/public/uploads/${item.gambar_barang}`}
                        alt={item.nama_barang}
                        className="img-thumbnail-custom"
                      />
                    </div>
                    <div className="col-md-8 d-flex align-items-center">
                      <div className="card-body flex-grow-1">
                        <h5 className="card-title">{item.nama_barang}</h5>
                        <p className="card-text">
                          Harga : {renderHarga(item.harga)}
                        </p>
                        <p className="card-text">Jumlah: {item.jumlah}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="col-md-4">
          <div className="card mb-3">
            <div className="card-body">
              <h5 className="card-title">Alamat Pengiriman</h5>
              <textarea
                className="form-control mb-4"
                placeholder="Masukkan alamat pengiriman"
                value={alamat}
                onChange={(e) => setAlamat(e.target.value)}
              ></textarea>
              {error && <p className="text-danger">{error}</p>}
              <h5 className="me-3">Total : {renderHarga(total)}</h5>
              <div className="button-container">
                <button
                  className="btn btn-primary-back"
                  onClick={handleBackToCart}
                >
                  Kembali
                </button>
                <button
                  className="btn btn-success-confirm"
                  onClick={handleConfirmOrder}
                >
                  Konfirmasi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleModalClose}>
              &times;
            </span>
            <h2>Pilih Metode Pembayaran</h2>
            <button
              className="payment-button bank-transfer"
              onClick={() => handlePaymentMethodSelect("bank_transfer")}
            >
              Transfer Bank
            </button>
            <button
              className="payment-button gopay"
              onClick={() => handlePaymentMethodSelect("gopay")}
            >
              Gopay
            </button>
          </div>
        </div>
      )}
      {gopay && imageUrl && (
        <div className="modal">
          <div className="modal-content gopay-qr-container">
            <span className="close" onClick={handleModalClose}>
              &times;
            </span>
            <h2>Scan QR Gopay</h2>
            <Gopay className="gopay-qr-image" imageUrl={imageUrl} />
            <button className="btn btn-finish" onClick={handleModalClose}>
              Selesai
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
